import dynamic from 'next/dynamic'
import bannerConfigAggregator from './banner/config-aggregator'
import sliderConfigAggregator from './slider/config-aggregator'
import BannerListingShimmer from '/src/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-listing/banner-listing-shimmer'
import BannerMenuNodeShimmer from '/src/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-menu-node/banner-menu-node-shimmer'
import BannerMultipleCtasShimmer from '/src/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-multiple-ctas/banner-multiple-ctas-shimmer'
import SliderAccordionShimmer from '/src/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/slider-accordion/slider-accordion-shimmer'

const BannerListing = dynamic(
	() => import('/src/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-listing'),
	{ loading: BannerListingShimmer }
)

const BannerMenuNode = dynamic(
	() =>
		import('/src/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-menu-node'),
	{ loading: BannerMenuNodeShimmer }
)

const BannerMultipleCtas = dynamic(
	() =>
		import(
			'/src/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-multiple-ctas'
		),
	{ loading: BannerMultipleCtasShimmer }
)

const SliderAccordion = dynamic(
	() =>
		import('/src/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/slider-accordion'),
	{ loading: SliderAccordionShimmer }
)

const bannerListingConfig = { component: BannerListing, configAggregator: bannerConfigAggregator }
const bannerMenuNodeConfig = { component: BannerMenuNode, configAggregator: bannerConfigAggregator }
const bannerMultipleCtasConfig = { component: BannerMultipleCtas, configAggregator: bannerConfigAggregator }
const sliderAccordionConfig = { component: SliderAccordion, configAggregator: sliderConfigAggregator }

const customContentTypes = {
	'banner-listing': bannerListingConfig,
	'banner-menu-node': bannerMenuNodeConfig,
	'banner-multiple-ctas': bannerMultipleCtasConfig,
	'slider-accordion': sliderAccordionConfig,
}

export default customContentTypes
